
<template>
  <div>
    <div class="top-padding">
      <div class="pay-now-page">
        <b-container>
          <div class="pay-now-page-wrap">
            <div class="thanks-order-block">
              <!-- Order Sucess Message -->
              <div class="thanks-order-info">
                <h1 class="top-heading">{{ $t("thank_you_oder") }}</h1>
                <p class="para-h">
                  {{ $t("order_success_1") }}
                  <b-link :href="'mailto:' + user.email" class="span-txt">{{
                    user.email
                  }}</b-link>
                  {{ $t("order_success_2") }}
                </p>
              </div>

              <!-- Account Button -->
              <div class="thanks-order-button">
                <!-- If logged In -->
                <b-link
                  to="/account/"
                  class="popup-link bg-success"
                  v-show="isLoggedIn"
                >
                  {{ $t("to_account") }}
                  <span class="ml-2"
                    ><font-awesome-icon icon="chevron-circle-right"
                  /></span>
                </b-link>

                <!-- If Not Logged In but user has account -->
                <b-link
                  v-if="!isLoggedIn && emailExists"
                  to="/account/"
                  class="popup-link bg-success"
                >
                  {{ $t("Click here to login to your account") }}
                  <span class="ml-2"
                    ><font-awesome-icon icon="chevron-circle-right"
                  /></span>
                </b-link>

                <!-- If Not Logged In and user does not has account -->
                <b-link
                  to="/account/"
                  class="popup-link bg-success mb-2"
                  v-else-if="!emailExists && !showPasswordField"
                  @click.prevent="showPasswordFields()"
                >
                  {{ $t("create_account") }}
                  <span class="ml-2"
                    ><font-awesome-icon icon="chevron-circle-right"
                  /></span>
                </b-link>
              </div>

              <div v-if="showPasswordField" class="thanks-order-button">
                <b-row
                  ><b-col cols="12">
                    <b-form-group
                      id="create-acc-password-group"
                      :label="$t('form_password')"
                      label-for="password"
                    >
                      <b-form-input
                        id="create-acc-password"
                        key="create-acc-password"
                        type="password"
                        v-model="form.password"
                        :state="form.passwordState"
                        aria-describedby="create-acc-password-feedback"
                      ></b-form-input>
                      <client-only>
                        <password-meter :password="form.password" />
                      </client-only>
                      <b-form-invalid-feedback
                        id="create-acc-password-feedback"
                      >
                        {{ form.passwordError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      id="create-acc-password-confirm-group"
                      :label="$t('form_password_confirmation') + '*'"
                      label-for="password_confirm"
                    >
                      <b-form-input
                        id="create-acc-password-confirm"
                        type="password"
                        v-model="form.password_confirm"
                        :state="form.password_confirmState"
                        aria-describedby="create-acc-password-confirm-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="create-acc-password-confirm-feedback"
                      >
                        {{ form.password_confirmError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col>
                    <b-btn-group id="passwordButtonGroup">
                      <b-btn
                        variant="success"
                        @click="createAccount"
                        class="popup-link"
                        >Maak account aan</b-btn
                      >
                    </b-btn-group>
                  </b-col>
                </b-row>
              </div>

              <!-- Order Information Card -->
              <div class="order-information-card" v-if="renderComponent">
                <div class="header-information">
                  <div class="detail">
                    <font-awesome-icon icon="box" class="icon mr-2" />
                    <h6>{{ $t("order_information_title") }}</h6>
                  </div>
                </div>

                <div class="detailed-information">
                  <div class="info-row">
                    <span>{{ $t("order_number") }}:</span>
                    <span>{{ orderDetails.number }}</span>
                  </div>
                  <div class="info-row">
                    <span>{{ $t("order_date") }}:</span>
                    <span>{{ orderDetails.order_date | formatDate }}</span>
                  </div>

                  <!-- If logged In -->
                  <p v-if="isLoggedIn">
                    <b-link to="/account/">{{
                      $t("view_status_order")
                    }}</b-link>
                  </p>

                  <!-- If Not Logged In but user has account -->
                  <p v-if="!isLoggedIn && emailExists">
                    <b-link to="/account/">
                      {{ $t("Please_log_in_to_view_the_status_of_your_order") }}
                    </b-link>
                  </p>

                  <!-- If Not Logged In and user does not has account -->
                  <p v-if="!emailExists && !showPasswordField">
                    <b-link to="/account/">
                      {{ $t("create_account") }}
                    </b-link>
                  </p>
                </div>
              </div>

              <!-- Address Card -->
              <!-- <div class="address-detail-card" v-if="renderComponent">
                <div class="header-information">
                  <div class="detail">
                    <font-awesome-icon icon="location-dot" class="icon mr-2" />
                    <h6>{{ $t("address") }}</h6>
                  </div>
                </div>

                <div class="detailed-information">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      v-if="orderDetails.shipping_address"
                    >
                      <span><strong>Bezorgadres:</strong></span>
                      <span v-if="orderDetails.shipping_address.company">
                        {{ orderDetails.shipping_address.company }}
                      </span>
                      <span v-if="orderDetails.shipping_address.firstname">
                        {{ orderDetails.shipping_address.firstname }}
                        {{ orderDetails.shipping_address.lastname }}
                      </span>
                      <span v-if="orderDetails.shipping_address.street">
                        {{ orderDetails.shipping_address.street[0] }}
                      </span>
                      <span v-if="orderDetails.shipping_address.postcode">
                        {{ orderDetails.shipping_address.postcode }}
                      </span>
                      <span v-if="orderDetails.shipping_address.city">
                        {{ orderDetails.shipping_address.city }}
                      </span>
                      <span v-if="orderDetails.shipping_address.country_code">
                        {{ orderDetails.shipping_address.country_code }}
                      </span>
                      <span v-if="orderDetails.shipping_address.telephone">
                        {{ orderDetails.shipping_address.telephone }}
                      </span>
                      <span v-if="orderDetails.customer_email">
                        {{ orderDetails.customer_email }}
                      </span>
                      <span v-if="orderDetails.shipping_address.vat_id">
                        {{ orderDetails.shipping_address.vat_id }}
                      </span>
                      <span v-if="orderDetails.shipping_address.coc">
                        {{ orderDetails.shipping_address.coc }}
                      </span>
                    </b-col>

                    <b-col cols="12" md="6" v-if="orderDetails.billing_address">
                      <span><strong>Factuuradres:</strong></span>
                      <span v-if="orderDetails.billing_address.company">
                        {{ orderDetails.billing_address.company }}
                      </span>
                      <span v-if="orderDetails.billing_address.firstname">
                        {{ orderDetails.billing_address.firstname }}
                        {{ orderDetails.billing_address.lastname }}
                      </span>
                      <span v-if="orderDetails.billing_address.street">
                        {{ orderDetails.billing_address.street[0] }}
                      </span>
                      <span v-if="orderDetails.billing_address.postcode">
                        {{ orderDetails.billing_address.postcode }}
                      </span>
                      <span v-if="orderDetails.billing_address.city">
                        {{ orderDetails.billing_address.city }}
                      </span>
                      <span v-if="orderDetails.billing_address.country_code">
                        {{ orderDetails.billing_address.country_code }}
                      </span>
                      <span v-if="orderDetails.billing_address.telephone">
                        {{ orderDetails.billing_address.telephone }}
                      </span>
                      <span v-if="orderDetails.customer_email">
                        {{ orderDetails.customer_email }}
                      </span>
                      <span v-if="orderDetails.billing_address.vat_id">
                        {{ orderDetails.billing_address.vat_id }}
                      </span>
                      <span v-if="orderDetails.billing_address.coc">
                        {{ orderDetails.billing_address.coc }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div> -->

              <!-- Product Detail Card -->
              <!-- <div class="product-detail-card" v-if="renderComponent">
                <div class="header-information">
                  <b-row>
                    <b-col cols="12" md="8">
                      <h6>{{ $t("Products") }}</h6>
                    </b-col>

                    <b-col cols="12" md="2" class="text-center">
                      <h6>{{ $t("no_of") }}</h6>
                    </b-col>

                    <b-col cols="12" md="2">
                      <h6>{{ $t("subtotal") }}</h6>
                    </b-col>
                  </b-row>
                </div>

                <div class="detailed-information" v-if="orderDetails.items">
                  <b-row
                    class="mb-4"
                    v-for="(data, index) in orderDetails.items"
                    :key="index"
                  >
                    <b-col cols="12" md="8">
                      <b-row>
                        <b-col cols="6">
                          <img
                            :src="data.image.url"
                            alt="product-image"
                            fluid
                          />
                        </b-col>
                        <b-col cols="6">
                          <h6 class="product-name">
                            {{ data.product_name }}
                          </h6>
                          <span>{{
                            formatCurrency(data.product_sale_price.value)
                          }}</span>
                          <span>Artikelnummer: #{{ data.product_sku }}</span>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12" md="2" class="text-center">
                      <span>{{ data.quantity_ordered }}</span>
                    </b-col>

                    <b-col cols="12" md="2" class="text-right">
                      <span>{{
                        formatCurrency(data.product_sale_price.value)
                      }}</span>
                    </b-col>
                  </b-row>

                  <div class="amount-details">
                    <div>
                      <strong>{{ $t("Subtotal_excl_delivery_costs") }}</strong>
                      <span>{{ $t("Delivery_or_collection_costs") }}</span>
                    </div>

                    <div class="text-right">
                      <strong v-if="orderDetails.total.subtotal">{{
                        formatCurrency(orderDetails.total.subtotal.value)
                      }}</strong>
                      <span v-if="orderDetails.total.total_shipping.value">{{
                        formatCurrency(orderDetails.total.total_shipping.value)
                      }}</span>
                    </div>
                  </div>

                  <div class="amount-details">
                    <div>
                      <strong>{{ $t("Total_excl_VAT") }}</strong>
                      <span>{{ $t("vat") }}</span>
                    </div>

                    <div class="text-right">
                      <strong v-if="orderDetails.total.grand_total.value">{{
                        formatCurrency(
                          orderDetails.total.grand_total.value -
                            (orderDetails.total.total_tax.value || 0)
                        )
                      }}</strong>
                      <span v-if="orderDetails.total.total_tax.value">{{
                        formatCurrency(orderDetails.total.total_tax.value)
                      }}</span>
                    </div>
                  </div>

                  <div class="amount-details">
                    <div>
                      <strong>{{ $t("Total_incl_VAT") }}</strong>
                    </div>

                    <div
                      class="text-right"
                      v-if="orderDetails.total.grand_total.value"
                    >
                      <strong>{{
                        formatCurrency(orderDetails.total.grand_total.value)
                      }}</strong>
                    </div>
                  </div>

                  <div
                    class="amount-details"
                    v-if="orderDetails.payment_methods"
                  >
                    <div>
                      <strong>
                        {{ $t("Paid_with") }}
                        {{ orderDetails.payment_methods[0].name }}
                      </strong>
                    </div>

                    <div
                      class="text-right"
                      v-if="orderDetails.total.total_paid.value == null"
                    >
                      <strong>€ 0</strong>
                    </div>
                    <div v-else>
                      <strong>{{
                        formatCurrency(orderDetails.total.total_paid.value)
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- Loader -->
              <div class="text-center mb-4" v-if="!renderComponent">
                <div class="pro-loader">
                  <div class="reverse-spinner"></div>
                </div>
              </div>

              <!-- Create Account Section for a Guest User -->
              <div
                class="create-account-information"
                v-if="!isLoggedIn && !emailExists"
              >
                <div class="header-information">
                  <div class="detail">
                    <h6>
                      {{
                        $t("Would_you_like_to_view_the_status_of_your_order")
                      }}?
                    </h6>
                  </div>
                </div>

                <div class="detailed-information">
                  <span
                    >{{
                      $t(
                        "Create_an_account_and_benefit_from_the_following_benefits"
                      )
                    }}:</span
                  >
                  <ul>
                    <li>{{ $t("View_all_your_orders_and_their_status") }}</li>
                    <li>
                      {{
                        $t(
                          "Put_products_on_your_wish_list_and_continue_looking_around"
                        )
                      }}!
                    </li>
                    <li>
                      {{
                        $t("Activate_notifications_for_product_availability")
                      }}
                    </li>
                  </ul>

                  <p class="mt-2">
                    <b-link to="/account/">{{
                      $t("Create_your_account_here")
                    }}</b-link>
                  </p>
                </div>
              </div>

              <div
                class="create-account-information p-2"
                v-if="!isLoggedIn && emailExists"
              >
                <div class="header-information">
                  <div class="detail">
                    <h6>
                      {{
                        $t("Would_you_like_to_view_the_status_of_your_order")
                      }}?
                    </h6>
                  </div>
                </div>

                <div class="detailed-information">
                  <b-link to="/account/">
                    {{ $t("Please_log_in_to_view_the_status_of_your_order") }}
                  </b-link>
                </div>
              </div>

              <!-- More Information -->
              <div class="help-section text-center">
                <h5>{{ $t("No_email_received") }}?</h5>
                <h5>
                  {{ $t("Please_contact_our") }}
                  <b-link to="/contact/">{{ $t("customer_service") }}</b-link>
                </h5>
                <h5>
                  {{ $t("or_view_our_most") }}
                  <b-link to="/faq/">{{
                    $t("frequently_asked_questions")
                  }}</b-link
                  >.
                </h5>
              </div>

              <!-- Continue Shopping -->
              <div class="continue-shopping text-center">
                <h3>{{ $t("Have_fun_with_your_new_purchase") }}!</h3>

                <div class="thanks-order-button mt-4">
                  <b-link to="/" class="popup-link bg-primary mb-2">
                    {{ $t("continue_shopping") }}
                    <span class="ml-2"
                      ><font-awesome-icon icon="chevron-circle-right"
                    /></span>
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
//import ClientOnly from "vue-client-only";
import { getOrderInfoFromHash } from "@storefront/core/data-resolver/cart";
import config from "@storefront/core/lib/utils/config";
import { checkEmailExist } from "@storefront/core/data-resolver/user";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "PaymentSuccess",
  components: {
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },

  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_success") },
        { name: "keywords", content: this.$t("payment_success") },
        { name: "description", content: this.$t("payment_success") },
      ],
      title: this.$t("payment_success"),
    };
  },

  filters: {
    formatDate(value) {
      if (!value) return "";
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${day}-${month}-${year} | ${hours}:${minutes}`;
    },
  },

  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },

    user: {
      get() {
        return this.$store.getters["user/getCurrentUser"];
      },
      set() {},
    },
    success_page() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "success_page"
      );
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },

  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    if (this.transactionId != null) {
      const hashId = localStorage.getItem("orderHash");
      const order = await getOrderInfoFromHash(hashId);
      this.orderDetails = await getOrderInfoFromHash(hashId);
      this.renderComponent = true;
      this.order_id = parseInt(atob(order.id));
      if (!this.$store.getters["user/getIsLoggedIn"]) {
        this.user.email = order.customer_email;
        if (!(await checkEmailExist(this.user.email))) {
          this.emailExists = true;
        } else {
          this.emailExists = false;
        }
      }
      if (order != false) {
        this.user.email = order.customer_email;
        if (this.$gtm.enabled() == true) {
          const items = [];
          order.items.forEach((element) => {
            let item = {};
            item.id = element.sku;
            item.name = element.name;
            item.price = element.product_sale_price;
            item.quantity = element.quantity_ordered;
            items.push(item);
          });
          if (this.$gtm.enabled()) {
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: this.transactionId,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
            });
          }
        }
      }
    }
    this.renderComponent = true;
  },

  data: () => ({
    emailExists: true,
    renderComponent: false,
    showPasswordField: false,
    form: {
      password: "",
      password_confirm: "",
    },
    order_id: null,
    orderDetails: {},
  }),

  methods: {
    async createAccount() {
      let accountCreated = false;
      let self = this;
      if (this.form.password == this.form.password_confirm) {
        accountCreated = await this.$store.dispatch(
          "user/createAccountFromOrder",
          {
            order_id: self.order_id,
            email: self.user.email,
            password: self.form.password,
          }
        );
      }
      if (accountCreated) {
        const msg = {
          type: "success",
          title: "account_create",
          text: this.$t("account_created"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        this.showPasswordField = false;
      } else {
        const msg = {
          type: "danger",
          title: "account_create",
          text: this.$t("account_not_created"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    showPasswordFields() {
      this.showPasswordField = true;
    },

    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.create-account-information a {
  color: #007bff;
  text-decoration: underline;
}

.pro-loader {
  margin-left: -5rem;
  margin-bottom: 3rem;
  padding: 100px 0px 120px 0px;
  .reverse-spinner {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @media screen and (max-width: 560px) {
    margin-left: -4rem;
    margin-bottom: 2rem;
    .reverse-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    opacity: 0.7;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
</style>
